<template>
  <lidaPage  :queryInfo="{isDistributionInfo:1,...queryInfo}" ref="page" :columns="tableOption" :query="getPostUrl" :formColumns="formColumns"
    subTitle="总报价单数">
    <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
      <el-tab-pane :label="item.title" :name="item.type" v-for="item in navs" :key="item.type"></el-tab-pane>
    </el-tabs>
    <template #receiptsState="scope">
      <div>{{ documentState[scope.row.receiptsState] }}</div>
    </template>
    <template #operation>
      <lida-table-column label="操作" width="50" fixed="right">
        <template #default="scope">
          <el-button @click="goDetails(scope.row)" type="text">查看</el-button>
        </template>
      </lida-table-column>
    </template>
    <template #formButton v-if="activeName === '0'">
      <el-button size="mini" type="primary" @click="addOfferOrder">新增</el-button>
    </template>
  </lidaPage>
  <addOfferOrder v-model="showOfferDialog" />
</template>

<script>
import Header from "@/components/publicComponent/lidaHeader.vue";
import SumNumber from "@/components/publicComponent/sumNumber.vue";
import addOfferOrder from "@/module/mysaleMudule/components/dialog/addOfferOrder.vue";
import { mapActions, mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      tableData: [],
      formColumns: [
        { type: "date", prop: "time", label: "报价日期" },
        { type: "input", prop: "wd", label: "综合搜索" },
        // {
        //   type: "select",
        //   prop: "documentState",
        //   label: "单据状态",
        //   options: [
        //     {
        //       value: "WAIT_QUOTE",
        //       label: "待报价",
        //     },
        //     {
        //       value: "COMPLETE_QUOTE",
        //       label: "已报价",
        //     },
        //     {
        //       value: "REVOKE",
        //       label: "已撤销",
        //     },
        //   ],
        // },
      ],
      userInfo: {},
      showOfferDialog: false,
      navs: [
        { title: "我的报价", type: "0" },
        { title: "我收到的报价", type: "1" },
      ],
      activeName: "0",
    };
  },
  components: {
    addOfferOrder,
  },
  methods: {
    ...mapActions("distributionMudule", [
      "pageByDis", //报价单列表
      "pageInitiative",
    ]),
    ...mapMutations("distributionMudule", ["setOfferSuccessType"]),
    deleteRow(index) {
      console.log(index);
    },
    goDetails(row) {
      //跳转到详情
      this.$router.push({
        path: "/mysale/customerOfferDetails",
        query: {
          id: row.id,
          inquiryId: row.inquiryId,
          type: this.activeName
        },
      });
    },
    addOfferOrder() {
      //新增报价单
      this.showOfferDialog = true;
    },
  },
  created() {
    console.log(this.$route.params);
    if (this.$route.params.data) {
      this.showOfferDialog = true;
    }
  },
  mounted() { },
  computed: {
    ...mapState("purchaseMudule", [
      "documentState", //状态
    ]),
    queryInfo() {
      return {
        isInitiativeQuote:
          this.activeName === "0" ? undefined : this.activeName,
      };
    },
    getPostUrl() {
      return this.activeName === "0" ? this.pageByDis : this.pageInitiative;
    },
    tableOption() {
      return [
        { label: "询价单号", prop: "inquiryNumber" },
        // { label: "单据状态", prop: "receiptsState" },
        this.activeName === "0"
          ? { label: "询价企业", prop: "buyerEnterpriseName" }
          : { label: "报价企业", prop: "sellerEnterpriseName" },
        this.activeName === "0"
          ? { label: "询价客户", prop: "buyerName" }
          : { label: "报价客户", prop: "sellerName" },
        this.activeName === "0"
          ? { label: "联系方式", prop: "buyerPhone" }
          : { label: "联系方式", prop: "sellerPhone" },
        { label: "报价品牌", prop: "quoteBrands" },
        { label: "报价金额", prop: "quoteAmt" },
        { label: "报价数量", prop: "qty" },
        { label: "项目名称", prop: "projectName" },
      ];
    },
  },
  watch: {
    activeName(v) {
      // this.$nextTick(() => {
      // 	this.queryInfo.pageNo = 1;
      // 	this.$refs.page.refresh();
      // })
    },
  },
};
</script>

<style scoped></style>
